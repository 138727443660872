@media (max-width: 767px) {
  body {
    padding-top: 120px;
  }
  .site-menubar {
    .site-menu {
      .site-menu-category{
        &:first-child {
          margin-top: 90px;
        }
      }
    }
  }
}


@media (max-width: 479px) {
  .page-login-v3 {
    .panel {
      width: auto;
      .panel-body {
        padding: 40px 25px 32px;
      }
    }
  }

}

@media (max-width: 375px) {
  .tooltip-inner {
    max-width: 190px;
    padding: 13px 18px;
  }
}

@media (max-width: 375px) {
  .tooltip-inner {
    max-width: 140px;
  }
}