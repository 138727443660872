@import '../../../main/scss/parts/variables';
@import '../../../main/scss/parts/mixins';
@import '../../../main/scss/parts/helpers';

.page {
  &.products {
    .page-header {
      padding: 24px 24px 0;
    }
    .page-content {
      .add {
        text-align: right;
        margin-bottom: 8px;
        a {
          font-family: 'OpenSans', sans-serif;
          font-size: 14px;
          line-height: 19px;
          color: $primary-color;
          i {
            margin-right: 8px;
            font-size: 18px;
          }
          &:hover {
            text-decoration: none;
            opacity: .8;
          }
        }
      }
      .category {
        .category-title {
          font-family: 'OpenSans', sans-serif;
          font-size: 12px;
          line-height: 17px;
          color: $grey-color;
          margin: 0;
          i {
            font-size: 18px;
            margin-right: 8px;
          }
        }
        .category-body {
          margin: 8px 0 24px;
          .form-group {
            margin-bottom: 5px;
            &.order {
              position: relative;
              float: right;
              .icon {
                position: absolute;
                right: 8px;
                top: 5px;
                font-size: 15px;
                color: $grey-color;
                z-index: 1;
              }
              .bootstrap-select {
                font-family: 'OpenSans', sans-serif;
                font-size: 12px;
                color: $grey-color;
                line-height: 17px;
                width: 160px;
                @include crossBrowser(border-radius, 2px);
                .dropdown-toggle {
                  background: transparent;
                  @include crossBrowser(box-shadow, none);
                  border: 0;
                  padding: 3px 24px 2px 14px;
                  .filter-option {
                    text-align: right;
                  }
                }
                .dropdown-menu {
                  background: #E3E3E3;
                  border: 0;
                  padding: 0;
                  @include crossBrowser(border-top-left-radius, 0);
                  @include crossBrowser(border-top-right-radius, 0);
                  margin-top: 0;
                  ul {
                    background: #E3E3E3;
                    li {
                      margin: 0;
                      a {
                        padding: 3px 19px 2px 14px;
                        text-align: right;
                        outline: none;
                        &:hover {
                          background: #E3E3E3 + 10;
                        }
                      }
                      &:hover {
                        background: #E3E3E3 + 10;
                      }
                    }
                  }
                }
                .bs-caret {
                  display: none;
                }
                &:hover, &.show {
                  background: #E3E3E3;
                }
                &.show {
                  @include crossBrowser(border-bottom-left-radius, 0);
                  @include crossBrowser(border-bottom-right-radius, 0);
                }
              }
            }
          }
          .panel {
            margin-bottom: 0;
            @include crossBrowser(border-radius, 0);
            @include crossBrowser(box-shadow, inset 0 -1px 0 0 #DDDDDD);
            &.initial {
              background: #f5f6f6;
            }
            .panel-body {
              padding: 10px 16px;
              .product {
                .img {
                  display: inline-block;
                  width: 52px;
                  text-align: center;
                  margin-right: 10px;
                  img {
                    max-width: 52px;
                    height: 52px;
                  }
                }
                .data {
                  display: inline-block;
                  vertical-align: middle;
                  span {
                    display: block;
                    font-family: 'OpenSans', sans-serif;
                    font-size: 16px;
                    color: #212B35;;
                    line-height: 22px;
                  }
                }
              }
              .info {
                margin: auto;
                span {
                  display: block;
                  font-family: 'OpenSans', sans-serif;
                  &:first-child {
                    font-size: 14px;
                    color: $grey-color;
                  }
                  &:last-child {
                    font-size: 12px;
                    color: #B1B1B1;
                  }
                }
              }
              .actions {
                margin: auto;
                padding-right: 32px;
                a {
                  display: none;
                  font-family: OpenSans;
                  font-size: 14px;
                  color: #5C6AC4;
                  margin-right: 16px;
                  span {
                    display: inline-block;
                    margin-left: 8px;
                  }
                  i {
                    font-size: 18px;
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                  &:hover {
                    text-decoration: none;
                    color: #5C6AC4 + 40;
                  }
                }

              }
            }
            &:hover {
              @include crossBrowser(box-shadow, 0 2px 7px 0px rgba(0, 0, 0, 0.07));
              cursor: pointer;
              z-index: 1;
              .panel-body {
                .actions {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
    .modal {
      .modal-dialog {
        width: 354px;
        height: 184px;
        margin-top: 170px;
        .modal-content {
          @include crossBrowser(border-radius, 0);
          .modal-header {
            padding: 10px 18px;
            .close {
              opacity: 1;
              outline: none;
              .icon {
                font-size: 18px;
                font-weight: 700;
                color: #757575;
              }
            }
          }
          .modal-body {
            padding: 5px 26px 20px;
            p {
              font-family: 'OpenSans', sans-serif;
              font-size: 14px;
              color: #212B35;
              text-align: center;
            }
          }
          .modal-footer {
            padding: 4px 28px 30px;
            .btn {
              width: 50%;
              font-family: 'OpenSans', sans-serif;
              font-size: 15px;
              @include crossBrowser(border-radius, 2px);
              padding: 14px 0 13px;
              &:first-child {
                color: #FFF;
                background: #F46363;
                border-color: #F46363;
                margin-right: 13px;
                &:hover {
                  background: #F46363 - 10;
                }

              }
              &:last-child {
                color: #757575;
                background: #fff;
                border-color: #B1B1B1;
                margin-left: 13px;
                &:hover {
                  background: #fff - 5;
                }
              }
            }
          }
        }
        .form-group {
          margin: 8px 0 0;
          label {
            font-family: 'OpenSans', sans-serif;
            font-size: 12px;
            line-height: 17px;
            color: #212B35;
            margin-bottom: 9px;
            sup {
              font-size: 12px;
              color: $error-color;
            }
          }
          textarea {
            font-family: 'OpenSans', sans-serif;
            font-size: 15px;
            color: #040E28;
            height: 120px;
            padding: 12px;
            border: 1px solid #DCE1EA;
            @include crossBrowser(border-radius, 3px);
            &:focus {
              border: 1px solid $primary-color;
            }
          }
          &.has-error {
            textarea {
              border: 1px solid $error-color;
            }

          }
        }
      }

    }
    & ~ .tooltip {
      width: 78px;
      margin-bottom: 4px !important;
      .tooltip-inner {
        font-family: 'OpenSans', sans-serif;
        font-size: 10px;
        line-height: 14px;
        color: #FFFFFF;
        background: #374075;
        &:before {
          border-top-color: #374075;
        }
      }
      &.show {
        opacity: 1;
      }
    }
  }
}