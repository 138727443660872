@import '../../../main/scss/parts/variables';
@import '../../../main/scss/parts/mixins';
@import '../../../main/scss/parts/helpers';

.page {
  &.store {
    .page-content {
      .favorite, .category {
        .category-title {
          font-family: 'OpenSans', sans-serif;
          font-size: 12px;
          line-height: 17px;
          color: $grey-color;
          margin: 0;
        }
        .category-body {
          margin: 8px 0 24px;
          .panel {
            margin-bottom: 0;
            @include crossBrowser(border-radius, 0);
            @include crossBrowser(box-shadow,  inset 0 -1px 0 0 #DDDDDD);
            .panel-body {
              padding: 10px 16px;
              .product {
                .img {
                  display: inline-block;
                  width: 52px;
                  text-align: center;
                  margin-right: 10px;
                  img {
                    max-width: 52px;
                    height: 52px;
                  }

                }
                span {
                  font-family: 'OpenSans', sans-serif;
                  font-size: 16px;
                  line-height: 22px;
                  color: #212B35;
                }
              }
              .info {
                margin: auto;
                div {
                  padding-right: 17px;
                  a {
                    display: none;
                    padding: 5px;
                    i {
                      font-size: 18px;
                      padding-right: 1px;
                      &.fa-star {
                        color: #F1C31D;
                      }
                      &.fa-star-o {
                        color: #979797;
                      }
                    }
                  }
                  span {
                    font-family: 'OpenSans', sans-serif;
                    font-size: 14px;
                    line-height: 19px;
                    color:$grey-color;
                    margin-left: 25px;
                  }
                }

              }
            }
            &:hover {
              @include crossBrowser(box-shadow, 0 2px 7px 0px rgba(0, 0, 0, 0.07));
              cursor: pointer;
              z-index: 1;
              .panel-body {
                .info {
                  div {
                    a {
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .favorite {
        .category-body {
          .panel {
            .panel-body {
              .info {
                div {
                  a {
                    display: inline-block !important;
                    &.add {
                      display: none !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

   &~.tooltip {
      margin-bottom: 4px !important;
     .tooltip-inner {
       font-family: 'OpenSans', sans-serif;
       font-size: 10px;
       line-height: 14px;
       color: #FFFFFF;
       background: #374075;
       &:before {
         border-top-color : #374075;
       }
     }
     &.show {
       opacity: 1;
     }
    }
  }
}