@import '../../../main/scss/parts/variables';
@import '../../../main/scss/parts/mixins';
@import '../../../main/scss/parts/helpers';

.page-login-v3 {
  &:before {
    background: #f9f9f9;
  }
  .page-content {
    .brand {
      h2 {
        &.brand-text {
          font-family: 'OpenSans-Light', sans-serif;
          font-size: 18px;
          color: #06102A;
          margin: 26px 0 25px;
        }
      }
      img {
        max-width: 282px;
      }
    }
  }
  .panel {
    width: 380px;
    margin-top: 24px;
    margin-bottom: 0;
    @include crossBrowser(box-shadow, 0px 1px 14px 1px rgba(0,0,0,0.16));
    .panel-body {
      padding: 37px 40px 30px;
      form {
        margin: 0;
        .form-group {
          margin-bottom: 5px;
          label {
            float: left;
            font-family: 'OpenSans', sans-serif;
            font-size: 12px;
            color: #646D82;
            line-height: 17px;
            margin-bottom: 8px;
            a[data-toggle="tooltip"] {
              margin-left: 0;
                i{
                  display: inline-block;
                  margin-left: 4px;
                  font-size: 10px;
                  color: #99a6bc;
                }
            }
          }
          input {
            &.form-control {
              font-family: 'OpenSans', sans-serif;
              font-size: 15px;
              color: #040E28;
              height: 46px;
              padding: 12px 12px 14px;
              border-color: #DCE1EA;
              &:focus {
                border-color: $primary-color;
              }
            }
          }
          .error-msg  {
            font-family: 'OpenSans', sans-serif;
            font-size: 11px;
            color: $error-color;
            text-align: left;
            line-height: 18px;
            height: 18px;
            margin-top: 5px;
            opacity: 0;
            @include crossBrowser(transition, opacity .2s ease-in-out);
            span {
              display: none;
              &.server-msg {
                display: block;
              }
            }
          }
          &.has-error {
            input {
              border-color: $error-color;
            }
            .error-msg  {
              opacity: 1;
            }
          }
        }
        .btn {
          font-family: 'OpenSans', sans-serif;
          font-size: 15px;
          color: #fff;
          background: $primary-color;
          height: 47px;
          @include crossBrowser(border-radius, 2px);
          &:hover {
            background: $primary-color + 20;
          }
        }

        a {
          &.btn {
            margin: 0;
            line-height: 30px;
          }
        }


        .checkbox-custom {
          label {
            font-family: 'OpenSans', sans-serif;
            font-size: 12px;
            color: #939EAB;
            line-height: 20px;
            text-align: left;
            padding-left: 0;
            margin-bottom: 0;

            &:before, &:after {
              width: 16px;
              height: 16px;
              margin-top: 3px;
            }
            &:after {
              font-size: 10px;
              line-height: 14px;
              font-weight: 700;
            }
            a {
              margin-left: 0;
              color: $primary-color;
            }
          }
          input[type=checkbox] {
            &:checked {
              &+label {
                &:before {
                  border-width: 8px;
                  background-color: #fff;
                  border: 1px solid $primary-color;
                }
                &:after {
                  color: $primary-color;
                }
              }
            }
          }
          &.error {
            label {
              &:before {
                border: 1px solid $error-color;
              }
            }
          }
        }
      }
      p {
        font-family: 'OpenSans', sans-serif;
        font-size: 12px;
        line-height: 27px;
        margin-top: 16px;
        a {
          color: $primary-color;
        }
      }
      div {
        &.notice {
          text-align: left;
          margin-bottom: 40px;
          & > div {
           display: inline-block;
            &:first-child {
              width: 10%;
              vertical-align: top;
              padding-top: 3px;
              i {
                font-size: 18px;
                color: #22CE8B;
              }
            }
            &:last-child {
              width: 86%;
              span {
                font-family: 'OpenSans', sans-serif;
                font-size: 15px;
                line-height: 20px;
                color: #646D82;
              }
            }
          }
        }
      }
    }
  }

  footer {
    margin-top: 24px;
    p {
      font-family: 'OpenSans', sans-serif;
      font-size: 12px;
      color: #939EAB;
      line-height: 27px;
      a {
        color: #939EAB;
      }
    }
  }

  &.forgotten-password {
    .panel {
      .panel-body {
        padding: 47px 40px 30px;
      }
    }
  }
  &.page-confirm-message {
    .panel {
      margin-top: 32px;
    }
  }


  /**** tooltip ***/

  .tooltip {
    @include crossBrowser(transition, opacity .4s ease-in-out !important);
    .tooltip-inner {
      max-width: 208px;
      min-height: 66px;
      padding: 9px 36px 9px 14px;
      text-align: left;
      font-family: 'OpenSans-Light', sans-serif;
      font-size: 10px;
      color: #646D82;
      background-color: #fff;
      border-radius: 0;
      margin-left: 12px;
      opacity: 1;
      @include crossBrowser(box-shadow, 0px 1px 3px 0px rgba(0, 0, 0, 0.26));
      &:before {
        border-right-color: #fff !important;
        border-width: 10px 10px 10px 0 !important;
        margin-top: -10px !important;
        top: 50%;
        z-index: 1;
        margin-left: 6px;
      }
      &:after {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
        top: 50%;
        left: 0;
        margin-top: -10px;
        margin-left: 5px;
        content: '';
        border-width: 10px 10px 10px 0;
        border-right-color: rgba(0, 0, 0, 0.16);
        z-index: 0;
      }
    }
    &.show {
      opacity: 1;
    }
  }
}