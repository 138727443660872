@import '../../../main/scss/parts/variables';
@import '../../../main/scss/parts/mixins';
@import '../../../main/scss/parts/helpers';

$green: #66BA6D;
$orange: #FD9136;
$black: #000;
$red: red;

/** Media Queries **/

@media screen and (max-width: 1360px) {
  .page.versions .page-content .table-list table tr th {
    width: 15% !important;
  }
  .page.versions .page-content .table-list table tr td {
    width: 32%;
  }
  .page.versions .page-content .table-list table tr td.actions span a:last-child {
    margin-right: 15px !important;
  }
  .page.versions .page-content .table-list table tr td.actions span a i {
    line-height: 22px !important;
  }
}

.page {
  &.versions {
    .page-header {
      .page-title {
        padding-top: 10px;
      }
      .info-table {
        table {
          width: 210px;
          float: right;
          background: #fff;
          border: 1px solid #DADADA;
          tr {
            &:first-child {
              td {
                border-bottom: 1px solid #DADADA;
              }
            }
            td {
              width: 105px;
              font-family: 'OpenSans', sans-serif;
              font-size: 12px;
              color: $grey-color;
              padding: 8px 8px 7px;
              line-height: 17px;
              &:first-child {
                border-right: 1px solid #DADADA;
              }
            }
          }
        }
        i {
          font-size: 18px;
          margin-right: 8px;
        }
      }
    }
    .page-content {
      .add {
        text-align: right;
        margin: 16px 0;
        a {
          font-family: 'OpenSans', sans-serif;
          font-size: 14px;
          line-height: 19px;
          color: $primary-color;
          i {
            margin-right: 8px;
            font-size: 18px;
          }
          &:hover {
            text-decoration: none;
            opacity: .8;
          }
        }
      }
      .table-list {
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 170px;
        table {
          width: 100%;
          min-width: 900px;
          background: #fff;
          margin-bottom: 5px;
          tr {
            th {
              width: 17%;
              font-family: 'OpenSans', sans-serif;
              font-weight: normal;
              font-size: 12px;
              color: $grey-color;
              padding: 8px 16px;
              @include crossBrowser(box-shadow, inset 0 -1px 0 0 #DDDDDD);
              .order {
                display: inline-block;
                margin-left: 3px;
                a {
                  i {
                    font-size: 11px;
                    color: #757575;
                  }
                  &:hover {
                    opacity: .7;
                  }
                }
              }
              &:first-child {
                text-align: center;
              }
              &:hover {
                .order {
                  display: inline-block !important;
                }
              }
            }
            td {
              font-family: 'OpenSans', sans-serif;
              font-size: 14px;
              color: $grey-color;
              padding: 10px 16px;
              @include crossBrowser(box-shadow, inset 0 -1px 0 0 #DDDDDD);
              &.num-version {
                .img {
                  display: inline-block;
                  width: 52px;
                  text-align: center;
                  margin-right: 10px;
                  img {
                    max-width: 52px;
                    height: 52px;
                  }
                }
                span {
                  font-size: 16px;
                  color: #212B35;
                  margin-left: 16px;
                }
              }
              &.state {
                .form-group {
                  position: relative;
                  width: 164px;
                  margin: 0;
                  .icon {
                    position: absolute;
                    right: 8px;
                    top: 9px;
                    font-size: 15px;
                    z-index: 1;
                  }
                  .bootstrap-select {
                    width: 100%;
                    font-family: 'OpenSans', sans-serif;
                    font-size: 12px;
                    color: $grey-color;
                    line-height: 17px;
                    .dropdown-toggle {
                      background: transparent;
                      @include crossBrowser(box-shadow, none);
                      border: 1px solid;
                      @include crossBrowser(border-radius, 2px);
                      padding: 8px 24px 7px 14px;
                    }
                    .dropdown-menu {
                      background: #E3E3E3;
                      border: 0;
                      padding: 0;
                      @include crossBrowser(border-top-left-radius, 0);
                      @include crossBrowser(border-top-right-radius, 0);
                      margin-top: 0;
                      ul {
                        background: #fff;
                        border-top: 0 !important;
                        li {
                          margin: 0;
                          a {
                            padding: 8px 19px 7px 14px;
                            outline: none;
                            &:hover {
                              background: #E3E3E3 + 20;
                            }
                          }
                          &:hover {
                            background: #E3E3E3 + 20;
                          }
                          &.selected {
                            display: none;
                          }
                        }
                      }
                    }
                    .bs-caret {
                      display: none;
                    }
                    &.show {
                      .dropdown-toggle {
                        @include crossBrowser(border-bottom-left-radius, 0);
                        @include crossBrowser(border-bottom-right-radius, 0);
                        border-bottom: 0;
                      }

                    }
                  }

                  &.first-state {
                    .icon {
                      color: $orange;
                    }
                    .bootstrap-select {
                      .dropdown-toggle {
                        color: $orange;
                        border-color: $orange;
                      }
                      .dropdown-menu {
                        ul {
                          border: 1px solid $orange;
                        }
                      }
                    }
                  }
                  &.second-state {
                    .icon {
                      color: $green;
                    }
                    .bootstrap-select {
                      .dropdown-toggle {
                        color: $green;
                        border-color: $green;
                      }
                      .dropdown-menu {
                        ul {
                          border: 1px solid $green;
                        }
                      }

                    }
                  }
                  &.third-state {
                    .icon {
                      color: $black;
                    }
                    .bootstrap-select {
                      .dropdown-toggle {
                        color: $black;
                        border-color: $black;
                      }
                      .dropdown-menu {
                        ul {
                          border: 1px solid $black;
                        }
                      }

                    }
                  }
                  &.fourth-state {
                    .icon {
                      color: $red;
                    }
                    .bootstrap-select {
                      .dropdown-toggle {
                        color: $red;
                        border-color: $red;
                      }
                      .dropdown-menu {
                        ul {
                          border: 1px solid $red;
                        }
                      }

                    }
                  }
                }
              }
              &.actions {
                text-align: right;
                span {
                  display: none;
                  a {
                    margin-right: 16px;
                    outline: none;
                    i {
                      font-size: 17px;
                      line-height: 17px;
                      color: $primary-color;
                    }
                    &:last-child {
                      margin-right: 8px;
                    }
                  }
                }
              }
            }
          }
          tbody {
            tr {
              &:hover {
                @include crossBrowser(box-shadow, 0 2px 7px 0px rgba(0, 0, 0, 0.07));
                cursor: pointer;
                z-index: 1;
                td {
                  &.actions {
                    span {
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }
        }
        div {
          &.no-result {
            font-family: 'OpenSans', sans-serif;
            font-size: 14px;
            background: #fff;
            text-align: center;
            height: 72px;
            padding-top: 26px;
          }
        }
      }
    }
    .modal {
      .modal-dialog {
        width: 354px;
        height: 184px;
        margin-top: 170px;
        .modal-content {
          @include crossBrowser(border-radius, 0);
          .modal-header {
            padding: 10px 18px;
            .close {
              opacity: 1;
              outline: none;
              .icon {
                font-size: 18px;
                font-weight: 700;
                color: #757575;
              }
            }
          }
          .modal-body {
            padding: 5px 26px 20px;
            p {
              font-family: 'OpenSans', sans-serif;
              font-size: 14px;
              color: #212B35;
              text-align: center;
            }
          }
          .modal-footer {
            padding: 4px 28px 30px;
            .btn {
              width: 50%;
              font-family: 'OpenSans', sans-serif;
              font-size: 15px;
              @include crossBrowser(border-radius, 2px);
              padding: 14px 0 13px;
              &:first-child {
                color: #FFF;
                background: #F46363;
                border-color: #F46363;
                margin-right: 13px;
                &:hover {
                  background: #F46363 - 10;
                }

              }
              &:last-child {
                color: #757575;
                background: #fff;
                border-color: #B1B1B1;
                margin-left: 13px;
                &:hover {
                  background: #fff - 5;
                }
              }
            }
          }
        }
        .form-group {
          margin: 8px 0 0;
          label {
            font-family: 'OpenSans', sans-serif;
            font-size: 12px;
            line-height: 17px;
            color: #212B35;
            margin-bottom: 9px;
            sup {
              font-size: 12px;
              color: $error-color;
            }
          }
          textarea {
            font-family: 'OpenSans', sans-serif;
            font-size: 15px;
            color: #040E28;
            height: 120px;
            padding: 12px;
            border: 1px solid #DCE1EA;
            @include crossBrowser(border-radius, 3px);
            &:focus {
              border: 1px solid $primary-color;
            }
          }
          &.has-error {
            textarea {
              border: 1px solid $error-color;
            }

          }
        }
      }

    }

    .add-permissions {
      width: 320px;
      padding: 0 10px;
      margin: 30px auto 0;
      .form-group {
        margin-bottom: 5px;
        label {
          font-family: 'OpenSans', sans-serif;
          font-size: 12px;
          line-height: 17px;
          color: #646D82;
          margin-bottom: 9px;
          sup {
            font-size: 12px;
            color: $error-color;
          }
        }
        input {
          font-family: 'OpenSans', sans-serif;
          font-size: 15px;
          color: #040E28;
          height: 44px;
          min-height: 44px;
          padding: 7px 12px;
          border: 1px solid #DCE1EA;
          @include crossBrowser(border-radius, 3px);
          &:focus {
            border: 1px solid $primary-color;
          }
        }
        .error-msg {
          font-family: 'OpenSans', sans-serif;
          font-size: 11px;
          color: $error-color;
          text-align: left;
          line-height: 18px;
          height: 18px;
          margin-top: 5px;
          opacity: 0;
          @include crossBrowser(transition, opacity .2s ease-in-out);
          span {
            display: none;
            &.server-msg {
              display: block;
            }
          }
        }
        &.has-error {
          input, textarea {
            border: 1px solid $error-color;
          }
          .error-msg {
            opacity: 1;
          }
        }
        &.disabled {
          opacity: 0.5;
        }
      }
      .modal-dialog {
        margin-top: 50px;
        .modal-content {
          width: 354px;
          margin: 30px auto !important;
          @include crossBrowser(border-radius, 2px);
          .modal-header {
            position: relative;
            padding: 5px 20px 0px !important;
            border: 1px solid #E8E8E8;
            h4 {
              margin: 0 auto;
              span {
                display: block;
                &:first-child {
                  font-family: 'OpenSans-Bold', sans-serif;
                  font-size: 18px;
                  line-height: 26px;
                  color: #212B35;
                  width: 100%;
                  text-align: center;
                }
                &:last-child {
                  font-family: 'OpenSans', sans-serif;
                  font-size: 12px;
                  color: #212B35;
                  line-height: 17px;
                  margin: 0 0 8px;
                }
              }
            }
            .close {
              position: absolute;
              right: 15px;
              top: 5px;
              outline: 0;
              &:hover {
                opacity: .7;
              }
            }
          }
          .modal-body {
            padding: 30px 26px 20px !important;
          }
          .modal-footer {
            padding: 0 27px 20px;
            .btn {
              &:first-child {
                background: $primary-color !important;
                border-color: $primary-color !important;
                &:hover {
                  opacity: 0.9;
                }
              }
              &.disabled {
                color: #A6A6A6 !important;
                background: #EFEFEF !important;
                border-color: #EFEFEF !important;
                opacity: .65 !important;
              }
            }
          }
        }
      }

    }
    .dropdown {
      &.group-list, &.users-list {
        position: relative;
        margin-bottom: 28px;
        .md-chevron-down {
          position: absolute;
          right: 6px;
          top: 14px;
          font-size: 22px;
          color: #040E28;
          z-index: 2;
        }
        .dropdown-toggle {
          width: 100%;
          height: 46px;
          font-family: 'OpenSans', sans-serif;
          font-size: 15px;
          color: #A6B1C2;
          text-align: left;
          background: #FFFFFF;
          border: 1px solid #DCE1EA;
          @include crossBrowser(border-radius, 3px);
          @include crossBrowser(box-shadow, none);
          &:after {
            display: none;
          }

        }
        .dropdown-menu {
          width: 100%;
          padding: 16px 7px 0px 16px;
          .search {
            margin-bottom: 16px;
            .input-search {
              width: 100%;
              height: 26px;
              input {
                height: 26px;
                min-height: 26px;
                background: #DCE1EA;
                font-family: 'OpenSans', sans-serif;
                font-size: 14px;
                color: $primary-color;
                padding: 4px 35px;
                @include crossBrowser(border-radius, 100px);
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                  color: $primary-color !important;
                  opacity: .5;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                  color: $primary-color !important;
                  opacity: .5;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                  color: $primary-color !important;
                  opacity: .5;
                }
                &:-moz-placeholder { /* Firefox 18- */
                  color: $primary-color !important;
                  opacity: .5;
                }

                &:focus {
                  border-color: $primary-color;
                }
              }
              .input-search-icon {
                left: 0;
                font-size: 18px;
                color: #5C6AC4;
              }
              .input-search-close {
                display: none;
                font-size: 11px;
                color: $primary-color;
                opacity: .8;
                &:hover {
                  opacity: 1;
                }
                &:focus {
                  outline: none;
                }
              }
            }
          }
          .filter {
            margin-bottom: 16px;
            .filter-btn {
              font-family: 'OpenSans-Semibold', sans-serif;
              font-size: 9px;
              text-transform: uppercase;
              color: #757575;
              padding: 4px 3px 3px;
              border: 1px solid #D8D8D8;
              border-radius: 2px;
              background: #fff;
              min-width: 34px;
              margin-bottom: 4px;
              &.active {
                color: #fff;
                background: #757575;
              }
              &:focus {
                @include crossBrowser(box-shadow, none);
              }

            }
          }
          border: 1px solid #E8E8E8;
          @include crossBrowser(box-shadow, 0 2px 3px 0 rgba(97, 97, 97, 0.16));
          @include crossBrowser(border-radius, 2px);
        }
        .members, .groups {
          max-height: 138px;
          overflow-x: scroll;
          padding-right: 8px;
          .member, .group {
            padding-right: 15px;
          }
        }
      }
      &.group-list {
        .groups {
          .group {
            padding: 5px 15px 5px 0;
            span {
              &.element {
                .data {
                  .name {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .member, .group {
      cursor: auto;
      padding: 0;
      margin: 0 0 5px 0;
      padding: 0 14px 0 2px;
      width: 100%;
      border-bottom: 1px solid #E2E2E2;
      span {
        display: inline-block;
        &.element {
          width: 90%;
          vertical-align: middle;
          .img {
            display: inline-block;
            vertical-align: top;
            img {
              max-width: 32px;
              border-radius: 50%;
            }
          }
          .data {
            display: inline-block;
            .name {
              display: block;
              font-family: 'OpenSans', sans-serif;
              font-size: 13px;
              line-height: 18px;
              color: #212B35;
            }
            .function {
              display: block;
              font-family: 'OpenSans', sans-serif;
              font-size: 10px;
              line-height: 14px;
              color: #B1B1B1;
            }
          }
        }
        &.action {
          width: 10%;
          text-align: right;
          .icons {
            cursor: pointer;
            font-size: 14px;
            &.icon-plus {
              color: #22CE8B;
            }
            &.icon-minus {
              color: #F46363;
            }
          }
        }
      }
      &:hover, &:focus {
        background: #fff;
      }
    }
    .members-list {
      padding: 0 5px 0 10px;
      .member, .group {
        .action {
          .icon-plus {
            display: none !important;
          }
          .icon-minus {
            display: inline-block !important;
          }
        }
        &:first-child {
          margin-top: 5px;
        }
        &:last-child {
          margin-bottom: 19px;
        }
      }
    }
    .groups-list {
      .group {
        padding: 5px 15px 5px 0;
        .action {
          .icon-plus {
            display: none !important;
          }
          .icon-minus {
            display: inline-block !important;
          }
        }
        &:first-child {
          margin-top: 5px;
        }
        &:last-child {
          margin-bottom: 19px;
        }
      }
    }

    & ~ .tooltip {
      width: 78px;
      margin-bottom: 4px !important;
      .tooltip-inner {
        font-family: 'OpenSans', sans-serif;
        font-size: 10px;
        line-height: 14px;
        color: #FFFFFF;
        background: #374075;
        &:before {
          border-top-color: #374075;
        }
      }
      &.show {
        opacity: 1;
      }
    }
  }
}