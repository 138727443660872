@import '../../../main/scss/parts/variables';
@import '../../../main/scss/parts/mixins';
@import '../../../main/scss/parts/helpers';

.add-user {
  padding-top: 0;
  .page {
    background: #fff;
    padding-bottom: 288px;
    .page-header {
      background: #fff;
      padding: 0;
      @include crossBrowser(box-shadow, inset 0 -1px 0 0 #DDDDDD);
      div {
        padding: 0;
        margin: 0;
        &.close {
          width: 68px;
          height: 60px;
          float: left;
          padding: 8px 13px;
          text-align: center;
          opacity: 1;
          @include crossBrowser(border-right, 1px solid #DDDDDD);
          i {
            font-size: 24px;
            color: #757575;

            padding-top: 11px
          }
          &:hover {
            opacity: .7;
          }
        }
        &.title {
          text-align: center;
          h2 {
            font-family: 'OpenSans-Bold', sans-serif;
            font-size: 18px;
            line-height: 24px;
            color: #212B35;
            margin: 20px 0 0;
          }
          h4 {
            font-family: 'OpenSans', sans-serif;
            font-size: 12px;
            color: #212B35;
            line-height: 17px;
            margin: 0 0 8px;
            span {
              color: #a6a6a6;
            }
          }
        }
        &.add {
          .btn {
            width: 202px;
            height: 60px;
            font-family: 'OpenSans', sans-serif;
            font-size: 15px;
            color: #FFFFFF;
            background: $primary-color;
            @include crossBrowser(border-radius, 0);
            border: 0;
            &.disabled {
              color: #A6A6A6;
              background: #EFEFEF;
              &:hover {
                background: #EFEFEF;
              }
            }
            &:hover {
              background: $primary-color + 20;
            }
          }
        }
      }
    }
    .page-content {
      background: #fff;
    }
    .form, .add-cl {
      width: 320px;
      padding: 0 10px;
      margin: 30px auto 0;
      .form-group{
        margin-bottom: 5px;
        label {
          font-family: 'OpenSans', sans-serif;
          font-size: 12px;
          line-height: 17px;
          color: #646D82;
          margin-bottom: 9px;
          sup {
            font-size: 12px;
            color: $error-color;
          }
        }
        input {
          font-family: 'OpenSans', sans-serif;
          font-size: 15px;
          color: #040E28;
          height: 44px;
          min-height: 44px;
          padding: 7px 12px;
          border: 1px solid #DCE1EA;
          @include crossBrowser(border-radius, 3px);
          &:focus {
            border: 1px solid $primary-color;
          }
        }
        .error-msg {
          font-family: 'OpenSans', sans-serif;
          font-size: 11px;
          color: $error-color;
          text-align: left;
          line-height: 18px;
          height: 18px;
          margin-top: 5px;
          opacity: 0;
          @include crossBrowser(transition, opacity .2s ease-in-out);
          span {
            display: none;
            &.server-msg {
              display: block;
              ul {
                padding: 0;
                list-style: none;
              }
            }
          }
        }
        &.has-error {
          input, textarea {
            border: 1px solid $error-color;
          }
          .error-msg {
            opacity: 1;
          }
        }
        &.disabled {
          opacity: 0.5;
        }
        &.role {
          padding-top: 20px;
          border-top: 1px solid #979797;
          &.second {
            display: none;
          }
        }
      }
      .form-group {
        &.client-list {
          position: relative;
          margin-bottom: 28px;
          .md-chevron-down {
            position: absolute;
            right: 15px;
            top: 42px;
            font-size: 22px;
            color: #040E28;
            z-index: 2;
          }
          .bootstrap-select {
            width: 100%;
            font-family: 'OpenSans', sans-serif;
            font-size: 15px;
            color: $grey-color;
            line-height: 17px;
            z-index: 1;
            .dropdown-toggle {
              background: #fff;
              @include crossBrowser(box-shadow, none);
              border: 1px solid #E8E8E8;
              @include crossBrowser(border-radius, 3px);
              padding: 13px 24px 13px 14px;
            }
            .dropdown-menu.open {
              max-height: 200px !important;
              height: 200px;
              background: #fff;
              @include crossBrowser(box-shadow, 0 2px 3px 0 rgba(97, 97, 97, 0.16));
              @include crossBrowser(border-radius, 2px);
              padding: 0;
              margin-top: 4px;
              ul {
                max-height: 200px !important;
                background: #fff;
                padding-bottom: 50px;
                overflow: hidden !important;
                li {
                  margin: 0;
                  a {
                    padding: 5px 19px 5px 14px;
                    outline: none;
                    &:hover {
                      background: #F9FAFC;
                    }
                  }
                  &:first-child {
                    display: none;
                  }
                  &:hover {
                    background: #F9FAFC;
                  }
                }
              }
            }
            .bs-caret {
              display: none;
            }
            &.show {
              & ~ .add-client {
                display: block;
              }
            }
          }
          .add-client {
            display: none;
            position: absolute;
            top: 230px;
            width: 100%;
            padding: 13px 16px 12px 16px;
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            border-radius: 2px;
            z-index: 1201;
            span {
              font-family: 'OpenSans', sans-serif;
              font-size: 14px;
              line-height: 17px;
              color: $primary-color;
              outline: none;
              cursor: pointer;
              i {
                margin-right: 8px;
                font-size: 18px;
              }
              &:hover {
                text-decoration: none;
                opacity: .8;
              }
            }
          }
        }
      }

      div {
        .check {
          position: relative;
          & > div {
            position: absolute;
            right: 0;
            top: 28px;
            .text {
              display: inline-block;
              font-family: 'OpenSans', sans-serif;
              font-size: 12px;
              color: #939EAB;
              line-height: 20px;
              &:first-child {
                padding-right: 22px;
              }
              &:last-child {
                padding-left: 8px;
              }
            }
            .checkbox-custom {
              padding-left: 0;
              float: none !important;
              label {
                &:before, &:after {
                  width: 16px;
                  height: 16px;
                  margin-top: 3px;
                  margin-left: -15px;
                }
                &:after {
                  font-size: 10px;
                  line-height: 14px;
                  font-weight: 700;
                }
                a {
                  margin-left: 0;
                  color: $primary-color;
                }
              }
              input[type=checkbox] {
                &:checked {
                  & + label {
                    &:before {
                      border-width: 8px;
                      background-color: #fff;
                      border: 1px solid $primary-color;
                    }
                    &:after {
                      color: $primary-color;
                    }
                  }
                }
              }
              &.error {
                label {
                  &:before {
                    border: 1px solid $error-color;
                  }
                }
              }
            }
          }

        }

      }
      .btn-add {
        width: 100%;
        font-family: 'OpenSans', sans-serif;
        font-size: 15px;
        color: #FFFFFF;
        background: $primary-color;
        @include crossBrowser(border-radius, 0);
        border: 0;
        margin-top: 32px;
        padding: 14px 5px 13px;
        &.disabled {
          color: #A6A6A6;
          background: #EFEFEF;
          &:hover {
            background: #EFEFEF;
          }
        }
        &:hover {
          background: $primary-color + 20;
        }
      }
    }
    .add-cl {
      width: auto;
      .modal-content {
        width: 354px;
        margin: 68px auto 0;
        @include crossBrowser(border-radius, 2px);
        .modal-header {
          position: relative;
          padding: 10px 20px 6px;
          border: 1px solid #E8E8E8;
          h4 {
            font-family: 'OpenSans-Bold', sans-serif;
            font-size: 18px;
            line-height: 26px;
            color: #212B35;
            width: 100%;
            text-align: center;
          }
          .close {
            position: absolute;
            right: 20px;
            outline: 0;
          }
        }
        .modal-body {
          padding: 18px 27px 0;
        }
        .modal-footer {
          padding: 0 27px 20px;
          .btn {
            width: 100%;
            background: $primary-color;
            font-family: 'OpenSans', sans-serif;
            font-size: 15px;
            line-height: 20px;
            color: #FFFFFF;
            padding: 14px 20px 13px;
            &:hover {
              opacity: .9;
            }
          }
        }
      }
    }
  }
}


