@import '../../../main/scss/parts/variables';
@import '../../../main/scss/parts/mixins';
@import '../../../main/scss/parts/helpers';

.page {
  &.applications {
    .page-content {
      .category {
        .category-title {
          font-family: 'OpenSans', sans-serif;
          font-size: 12px;
          line-height: 17px;
          color: $grey-color;
          margin: 0;
          margin-bottom: 5px;
          i {
            font-size: 18px;
            margin-right: 8px;
          }
        }
        .category-body {
          margin: 8px 0 24px;
          .form-group {
            margin-bottom: 5px;
            &.order {
              position: relative;
              float: right;
              .icon {
                position: absolute;
                right: 8px;
                top: 5px;
                font-size: 15px;
                color: $grey-color;
                z-index: 1;
              }
              .bootstrap-select {
                font-family: 'OpenSans', sans-serif;
                font-size: 12px;
                color: $grey-color;
                line-height: 17px;
                width: 160px;
                @include crossBrowser(border-radius, 2px);
                .dropdown-toggle {
                  background: transparent;
                  @include crossBrowser(box-shadow, none);
                  border: 0;
                  padding: 3px 24px 2px 14px;
                  .filter-option {
                    text-align: right;
                  }
                }
                .dropdown-menu {
                  background: #E3E3E3;
                  border: 0;
                  padding: 0;
                  @include crossBrowser(border-top-left-radius, 0);
                  @include crossBrowser(border-top-right-radius, 0);
                  margin-top: 0;
                  ul {
                    background: #E3E3E3;
                    li {
                      margin: 0;
                      a {
                        padding: 3px 19px 2px 14px;
                        text-align: right;
                        outline: none;
                        &:hover {
                          background: #E3E3E3 + 10;
                        }
                      }
                      &:hover {
                        background: #E3E3E3 + 10;
                      }
                    }
                  }
                }
                .bs-caret {
                  display: none;
                }
                &:hover, &.show {
                  background: #E3E3E3;
                }
                &.show {
                  @include crossBrowser(border-bottom-left-radius, 0);
                  @include crossBrowser(border-bottom-right-radius, 0);
                }
              }
            }
          }
          .panel {
            margin-bottom: 0;
            @include crossBrowser(border-radius, 0);
            @include crossBrowser(box-shadow, inset 0 -1px 0 0 #DDDDDD);
            &.initial {
              background: #ecececc2;
            }
            .panel-body {
              padding: 10px 16px;
              .product {
                .img {
                  display: inline-block;
                  width: 52px;
                  text-align: center;
                  margin-right: 10px;
                  img {
                    max-width: 52px;
                    height: 52px;
                  }
                }
                .data {
                  display: inline-block;
                  vertical-align: middle;
                  span {
                    display: block;
                    font-family: 'OpenSans', sans-serif;
                    &:first-child {
                      font-size: 16px;
                      color: #212B35;
                      line-height: 22px;
                    }
                    &:last-child {
                      font-size: 12px;
                      color: #A6A6A6;
                      line-height: 17px;
                    }

                  }
                }
              }
              .info {
                margin: auto;
                span {
                  display: block;
                  font-family: 'OpenSans', sans-serif;
                  &:first-child {
                    font-size: 14px;
                    color: $grey-color;
                  }
                  &:last-child {
                    font-size: 12px;
                    color: #B1B1B1;
                  }
                }
              }
              .add {
                margin: auto;
                padding-right: 32px;
                a {
                  display: none;
                  font-family: OpenSans;
                  font-size: 14px;
                  color: #5C6AC4;
                  span {
                    display: inline-block;
                    margin-left: 8px;
                  }
                  i {
                    font-size: 18px;
                  }
                  &:hover {
                    text-decoration: none;
                    color: #5C6AC4 + 40;
                  }
                }

              }
            }
            &:hover {
              @include crossBrowser(box-shadow, 0 2px 7px 0px rgba(0, 0, 0, 0.07));
              cursor: pointer;
              z-index: 1;
              .panel-body {
                .add {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}