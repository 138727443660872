@import '../../../main/scss/parts/variables';
@import '../../../main/scss/parts/mixins';
@import '../../../main/scss/parts/helpers';

.page {
  &.account {
    .page-header {
      padding: 24px 24px;
    }
    .page-content {
      &>div {
        background: #fff;
        padding: 10px 20px;
        min-height: 500px;
        .first-block {
          padding: 0 0 30px;
          border-bottom: 1px solid $grey-color;
          .data-block {
            .form-group-file {
              &.icon {
                display: inline-block;
                vertical-align: middle;
                input {
                  padding: 0;
                }
                .dropify-wrapper {
                  width: 98px;
                  height: 25px;
                  border: 0;
                  background: #EFEFEF;
                  @include crossBrowser(border-radius, 2px);
                  .file-icon {
                    display: none;
                  }
                  .dropify-message {
                    p {
                      font-family: OpenSans;
                      font-size: 10px;
                      color: $primary-color;
                      line-height: 21px;
                      margin-top: 0;
                      vertical-align: middle;
                      span {
                        color: $primary-color;
                      }
                      &:not(.dropify-error) {
                        display: inline-block;
                      }
                      &.dropify-error {
                        color: $error-color;
                        font-weight: 400;
                      }

                    }
                  }
                  .dropify-preview {
                    .dropify-infos {
                      .dropify-infos-inner {
                        margin: 0;
                        padding: 0 5px;
                        .dropify-filename {
                          display: none;
                        }
                        .dropify-infos-message {
                          font-family: 'OpenSans', sans-serif;
                          font-weight: normal;
                          margin: 0;
                          padding: 0;
                          overflow: visible;
                          white-space: normal;
                          line-height: 14px;
                          opacity: .7;
                          &:before {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                  &.has-error {
                    .dropify-message {
                      display: none;
                    }
                  }
                  &.has-preview {
                    width: 80px;
                    height: 80px;
                    border: none;
                    padding: 0;
                    .dropify-preview {
                      padding: 0;
                    }
                  }
                }
                .dropify-errors-container {
                  padding-top: 2px;
                  ul {
                    padding-left: 0;
                    li {
                      font-family: 'OpenSans', sans-serif;
                      font-size: 10px;
                      line-height: 10px;
                      list-style: none;
                      color: $error-color;
                    }
                  }
                }
              }
            }
            .data {
              display: inline-block;
              margin-left: 20px;
              vertical-align: middle;
              h2 {
                font-family: 'OpenSans', sans-serif;
                font-size: 18px;
                line-height: 22px;
                margin: 0 0 5px;

              }
              h4 {
                font-family: 'OpenSans', sans-serif;
                font-size: 14px;
                line-height: 18px;
                margin: 0 0 10px;

              }
              span {
                font-family: 'OpenSans-Semibold', sans-serif;
                font-size: 12px;
                color: #757575;
                padding: 4px 6px 3px;
                border: 1px solid #D8D8D8;
                @include crossBrowser(border-radius, 4px);

                background: #fff;
                min-width: 34px;
                margin-bottom: 4px;
              }
            }
          }
          .logout-block {
            margin: auto;
            text-align: right;
            a {
              color: $primary-color;
              text-decoration: none;
              vertical-align: top;
              i {
                margin-right: 10px;
                font-size: 18px;
              }
              &:hover {
                opacity: .8;
              }
            }
          }
          .btn {
            background:  $primary-color;
            border-color: $primary-color;
            margin-top: 40px;
            width: auto;
            padding: 7px 23px;
            &.disabled {
              color: #A6A6A6;
              background: #EFEFEF;
              border-color: #EFEFEF;
            }
          }
        }
        .second-block {
          margin-top: 20px;
          a {
            &.showpwd {
              color: $primary-color;
              text-decoration: none;
              vertical-align: top;
              i {
                margin-left: 10px;
                font-size: 14px;
              }
              &:hover {
                opacity: .8;
              }
            }
          }
          .pwd-block {
            max-width: 300px;

            form {
              margin: 0;
              .form-group {
                margin-bottom: 5px;
                label {
                  float: left;
                  font-family: 'OpenSans', sans-serif;
                  font-size: 12px;
                  color: #646D82;
                  line-height: 17px;
                  margin-bottom: 8px;
                  a[data-toggle="tooltip"] {
                    margin-left: 0;
                    i{
                      display: inline-block;
                      margin-left: 4px;
                      font-size: 10px;
                      color: #99a6bc;
                    }
                  }
                }
                input {
                  &.form-control {
                    font-family: 'OpenSans', sans-serif;
                    font-size: 15px;
                    color: #040E28;
                    height: 46px;
                    padding: 12px 12px 14px;
                    border-color: #DCE1EA;
                    &:focus {
                      border-color: $primary-color;
                    }
                  }
                }
                .error-msg  {
                  font-family: 'OpenSans', sans-serif;
                  font-size: 11px;
                  color: $error-color;
                  text-align: left;
                  line-height: 18px;
                  height: 18px;
                  margin-top: 5px;
                  opacity: 0;
                  @include crossBrowser(transition, opacity .2s ease-in-out);
                  span {
                    display: none;
                    &.server-msg {
                      display: block;
                      ul {
                        padding: 0;
                        list-style: none;
                      }
                    }
                  }
                }
                &.has-error {
                  input {
                    border-color: $error-color;
                  }
                  .error-msg  {
                    opacity: 1;
                  }
                }
              }
              .btn {
                font-family: 'OpenSans', sans-serif;
                font-size: 15px;
                color: #fff;
                background: $primary-color;
                width: 160px ;
                height: 47px;
                @include crossBrowser(border-radius, 2px);
                &:hover {
                  background: $primary-color + 20;
                }
              }

              a {
                &.btn {
                  margin: 0;
                  line-height: 30px;
                }
              }


              .checkbox-custom {
                label {
                  font-family: 'OpenSans', sans-serif;
                  font-size: 12px;
                  color: #939EAB;
                  line-height: 20px;
                  text-align: left;
                  padding-left: 0;
                  margin-bottom: 0;

                  &:before, &:after {
                    width: 16px;
                    height: 16px;
                    margin-top: 3px;
                  }
                  &:after {
                    font-size: 10px;
                    line-height: 14px;
                    font-weight: 700;
                  }
                  a {
                    margin-left: 0;
                    color: $primary-color;
                  }
                }
                input[type=checkbox] {
                  &:checked {
                    &+label {
                      &:before {
                        border-width: 8px;
                        background-color: #fff;
                        border: 1px solid $primary-color;
                      }
                      &:after {
                        color: $primary-color;
                      }
                    }
                  }
                }
                &.error {
                  label {
                    &:before {
                      border: 1px solid $error-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    /**** tooltip ***/
    &~.tooltip {
      @include crossBrowser(transition, opacity .4s ease-in-out !important);
      .tooltip-inner {
        max-width: 208px;
        min-height: 66px;
        padding: 9px 36px 9px 14px;
        text-align: left;
        font-family: 'OpenSans-Light', sans-serif;
        font-size: 10px;
        color: #646D82;
        background-color: #fff;
        border-radius: 0;
        margin-left: 12px;
        opacity: 1;
        @include crossBrowser(box-shadow, 0px 1px 3px 0px rgba(0, 0, 0, 0.26));
        &:before {
          border-right-color: #fff !important;
          border-width: 10px 10px 10px 0 !important;
          margin-top: -10px !important;
          top: 50%;
          z-index: 1;
          margin-left: 6px;
        }
        &:after {
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent;
          top: 50%;
          left: 0;
          margin-top: -10px;
          margin-left: 5px;
          content: '';
          border-width: 10px 10px 10px 0;
          border-right-color: rgba(0, 0, 0, 0.16);
          z-index: 0;
        }
      }
      &.show {
        opacity: 1;
      }
    }
  }
}
