@import '../../../main/scss/parts/variables';
@import '../../../main/scss/parts/mixins';
@import '../../../main/scss/parts/helpers';

.add-group {
  padding-top: 0;
  .page {
    background: #fff;
    padding-bottom: 288px;
    .page-header {
      background: #fff;
      padding: 0;
      @include crossBrowser(box-shadow, inset 0 -1px 0 0 #DDDDDD);
      div {
        padding: 0;
        margin: 0;
        &.close {
          width: 68px;
          height: 60px;
          float: left;
          padding: 8px 13px;
          text-align: center;
          opacity: 1;
          @include crossBrowser(border-right, 1px solid #DDDDDD);
          i {
            font-size: 24px;
            color: #757575;

            padding-top: 11px
          }
          &:hover {
            opacity: .7;
          }
        }
        &.title {
          text-align: center;
          h2 {
            font-family: 'OpenSans-Bold',sans-serif;
            font-size: 18px;
            line-height: 24px;
            color: #212B35;
            margin: 18px 0 2px;
          }
        }
        &.add {
          .btn {
            width: 202px;
            height: 60px;
            font-family: 'OpenSans',sans-serif;
            font-size: 15px;
            color: #FFFFFF;
            background: $primary-color;
            @include crossBrowser(border-radius, 0);
            border: 0;
            &.disabled {
              color: #A6A6A6;
              background: #EFEFEF;
              &:hover {
                background: #EFEFEF;
              }
            }
            &:hover {
              background: $primary-color + 20;
            }
          }
        }
      }
    }
    .page-content {
      background: #fff;
      .form {
        width: 320px;
        padding: 0 10px;
        margin: 30px auto 0;
        .form-group {
          margin-bottom: 5px;
          label {
            font-family: 'OpenSans',sans-serif;
            font-size: 12px;
            line-height: 17px;
            color: #646D82;
            margin-bottom: 9px;
            sup {
              font-size: 12px;
              color: $error-color;
            }
          }
          input {
            font-family: 'OpenSans',sans-serif;
            font-size: 15px;
            color: #040E28;
            height: 44px;
            min-height: 44px;
            padding: 7px 12px;
            border: 1px solid #DCE1EA;
            @include crossBrowser(border-radius, 3px);
            &:focus {
              border: 1px solid $primary-color;
            }
          }
          .error-msg  {
            font-family: 'OpenSans', sans-serif;
            font-size: 11px;
            color: $error-color;
            text-align: left;
            line-height: 18px;
            height: 18px;
            margin-top: 5px;
            opacity: 0;
            @include crossBrowser(transition, opacity .2s ease-in-out);
            span {
              display: none;
              &.server-msg {
                display: block;
              }
            }
          }
          .member{
            cursor: auto;
            padding: 0;
            margin: 0 0 5px 0;
            padding: 0 14px 0 2px;
            width: 100%;
            border-bottom: 1px solid #E2E2E2;
            span {
              display: inline-block;
              &.element {
                width: 90%;
                vertical-align: middle;
                .img {
                  display: inline-block;
                  vertical-align: top;
                  img {
                    max-width: 32px;
                    border-radius: 50%;
                  }
                }
                .data {
                  display: inline-block;
                  .name {
                    display: block;
                    font-family: 'OpenSans', sans-serif;
                    font-size: 13px;
                    line-height: 18px;
                    color: #212B35;
                  }
                  .function {
                    display: block;
                    font-family: 'OpenSans', sans-serif;
                    font-size: 10px;
                    line-height: 14px;
                    color: #B1B1B1;
                  }
                }
              }
              &.action {
                width: 10%;
                text-align: right;
                .icons {
                  cursor: pointer;
                  font-size: 14px;
                  &.icon-plus {
                    color: #22CE8B;
                  }
                  &.icon-minus {
                    color: #F46363;
                  }
                }
              }
            }
            &:hover, &:focus {
              background: #fff;
            }
          }
          .members-list {
            padding: 0 5px 0 10px;
            .member {
              .action {
                .icon-plus {
                  display: none !important;
                }
                .icon-minus {
                  display: inline-block !important;
                }
              }
              &:first-child {
                margin-top: 5px;
              }
              &:last-child {
                margin-bottom: 19px;
              }
            }
          }
          &.has-error {
            input, textarea{
              border: 1px solid $error-color;
            }
            .error-msg  {
              opacity: 1;
            }
          }
          &.disabled {
            opacity: 0.5;
          }
        }

        .dropdown {
          &.group-list {
            position: relative;
            margin-bottom: 28px;
            .md-chevron-down {
              position: absolute;
              right: 6px;
              top: 14px;
              font-size: 22px;
              color: #040E28;
              z-index: 2;
            }
            .dropdown-toggle {
              width: 100%;
              height: 46px;
              font-family: 'OpenSans', sans-serif;
              font-size: 15px;
              color: #A6B1C2;
              text-align: left;
              background: #FFFFFF;
              border: 1px solid #DCE1EA;
              @include crossBrowser(border-radius, 3px);
              @include crossBrowser(box-shadow, none);
              &:after {
                display: none;
              }

            }
            .dropdown-menu {
              width: 100%;
              padding: 16px 7px 0px 16px;
              .search {
                margin-bottom: 16px;
                .input-search {
                  width: 100%;
                  height: 26px;
                  input {
                    height: 26px;
                    min-height: 26px;
                    background: #DCE1EA;
                    font-family: 'OpenSans', sans-serif;
                    font-size: 14px;
                    color: $primary-color;
                    padding: 4px 35px;
                    @include crossBrowser(border-radius, 100px);
                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                      color:  $primary-color !important;
                      opacity: .5;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                      color: $primary-color !important;
                      opacity: .5;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                      color: $primary-color !important;
                      opacity: .5;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                      color: $primary-color !important;
                      opacity: .5;
                    }

                    &:focus {
                      border-color: $primary-color;
                    }
                  }
                  .input-search-icon {
                    left: 0;
                    font-size: 18px;
                    color: #5C6AC4;
                  }
                  .input-search-close {
                    display: none;
                    font-size: 11px;
                    color: $primary-color;
                    opacity: .8;
                    &:hover {
                      opacity: 1;
                    }
                    &:focus {
                      outline: none;
                    }
                  }
                }
              }
              .filter {
                margin-bottom: 16px;
                .filter-btn {
                  font-family: 'OpenSans-Semibold', sans-serif;
                  font-size: 9px;
                  text-transform: uppercase;
                  color: #757575;
                  padding: 4px 3px 3px;
                  border: 1px solid #D8D8D8;
                  border-radius: 2px;
                  background: #fff;
                  min-width: 34px;
                  margin-bottom: 4px;
                  &.active {
                    color: #fff;
                    background: #757575;
                  }
                  &:focus {
                    @include crossBrowser(box-shadow, none);
                  }

                }
              }
              border: 1px solid #E8E8E8;
              @include crossBrowser(box-shadow, 0 2px 3px 0 rgba(97,97,97,0.16));
              @include crossBrowser(border-radius, 2px);
            }
            .members {
              max-height: 138px;
              overflow-x: scroll;
              padding-right: 8px;
              .member {
                padding-right: 15px;
              }
            }
          }
        }
        .btn-add {
          width: 100%;
          font-family: 'OpenSans',sans-serif;
          font-size: 15px;
          color: #FFFFFF;
          background: $primary-color;
          @include crossBrowser(border-radius, 0);
          border: 0;
          margin-top: 10px;
          padding: 14px 5px 13px;
          &.disabled {
            color: #A6A6A6;
            background: #EFEFEF;
            &:hover {
              background: #EFEFEF;
            }
          }
          &:hover {
            background: $primary-color + 20;
          }
        }
      }
    }
  }
}
