@import '../../../main/scss/parts/variables';
@import '../../../main/scss/parts/mixins';
@import '../../../main/scss/parts/helpers';

.page {
  &.groups {
    .page-header {
      padding-bottom: 11px;
      .page-title {
      }
    }
    .page-content {
      .form-group {
        &.search {
          margin-bottom: 16px;
          .input-search {
            width: 268px;
            height: 26px;
            @include crossBrowser(border-radius, 100px);
            input {
              height: 26px;
              background: #DCE1EA;
              font-family: 'OpenSans', sans-serif;
              font-size: 14px;
              color: $primary-color;
              padding: 4px 35px;
              &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color:  $primary-color !important;
                opacity: .5;
              }
              &::-moz-placeholder { /* Firefox 19+ */
                color: $primary-color !important;
                opacity: .5;
              }
              &:-ms-input-placeholder { /* IE 10+ */
                color: $primary-color !important;
                opacity: .5;
              }
              &:-moz-placeholder { /* Firefox 18- */
                color: $primary-color !important;
                opacity: .5;
              }

              &:focus {
                border-color: $primary-color;
              }
            }
            .input-search-icon {
              left: 0;
              font-size: 18px;
              color: #5C6AC4;
            }
            .input-search-close {
              display: none;
              font-size: 11px;
              color: $primary-color;
              opacity: .8;
              &:hover {
                opacity: 1;
              }
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
      .add {
        text-align: right;
        padding-top: 6px;
        a {
          font-family: 'OpenSans', sans-serif;
          font-size: 14px;
          line-height: 19px;
          color: $primary-color;
          i {
            margin-right: 8px;
            font-size: 18px;
          }
          &:hover {
            text-decoration: none;
            opacity: .8;
          }
        }
      }
      .table-list {
        overflow-x: auto;
        overflow-y: hidden;
        table {
          width: 100%;
          min-width: 900px;
          background: #fff;
          margin-bottom: 5px;
          tr {
            th {
              width: 20%;
              font-family: 'OpenSans', sans-serif;
              font-weight: normal;
              font-size: 12px;
              color: $grey-color;
              padding: 7px 16px;
              @include crossBrowser(box-shadow, inset 0 -1px 0 0 #DDDDDD);
              .order {
                display: inline-block;
                margin-left: 3px;
                a {
                  i {
                    font-size: 11px;
                    color: #757575;
                  }
                  &:hover {
                    opacity: .7;
                  }
                }
              }
              &:hover {
                .order {
                  display: inline-block !important;
                }
              }
            }
            td {
              font-family: 'OpenSans', sans-serif;
              font-size: 14px;
              padding: 18px 16px;
              @include crossBrowser(box-shadow, inset 0 -1px 0 0 #DDDDDD);
              &.name {
                color: #212B35;
              }
              &.number {
                color: $grey-color;
              }
              &.actions {
                text-align: right;
                span {
                  display: none;
                  a {
                    display: inline-block;
                    margin-right: 16px;
                    outline: none;
                    i {
                      font-size: 17px;
                      line-height: 17px;
                      color: $primary-color;
                    }
                    &:last-child {
                      margin-right: 18px;
                    }
                  }
                }
              }
            }
          }
          thead {
            tr {
              display: table-row !important;
            }
          }
          tbody {
            tr {
              &:hover {
                @include crossBrowser(box-shadow, 0 2px 7px 0px rgba(0, 0, 0, 0.07));
                cursor: pointer;
                z-index: 1;
                td {
                  &.actions {
                    span {
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }
        }
        div {
          &.no-result {
            font-family: 'OpenSans', sans-serif;
            font-size: 14px;
            background: #fff;
            text-align: center;
            height: 72px;
            padding-top: 26px;
          }
        }
      }
      .table-nav {
        margin-top: 18px;
        .pagination {
          float: right;
          .page-item {
            .page-link {
              font-family: 'OpenSans', sans-serif;
              font-size: 13px;
              line-height: 18px;
              color: #A8A0B6;
              border: 1px solid #DDDDDD;
              padding: 5px 12px;
              background: #fff;
              i {
                font-size: 20px;
                color: #757575;
              }
              &.disabled {
                pointer-events: none;
                color: #c8c8c8;
              }
              &:hover, &:focus {
                background: #F4F5FC;
                color: $primary-color;
              }
            }
            &:first-child, &:last-child {
              .page-link {
                line-height: 20px;
                padding: 6px 12px 3px;
                &.disabled {
                  i {
                    color: #c8c8c8;
                  }
                }
                &:hover, &:focus {
                  background: #F4F5FC;
                  color: $primary-color;
                }
              }
            }
          }
        }
        .data {
          font-family: 'OpenSans', sans-serif;
          font-size: 12px;
          color: #757575;
          padding-top: 14px;
        }

      }
    }
    .modal {
      .modal-dialog {
        width: 354px;
        height: 184px;
        margin-top: 170px;
        .modal-content {
          @include crossBrowser(border-radius, 0);
          .modal-header {
            padding: 10px 18px;
            .close {
              opacity: 1;
              outline: none;
              .icon {
                font-size: 18px;
                font-weight: 700;
                color: #757575;
              }
            }
          }
          .modal-body {
            padding: 5px 26px 20px;
            p {
              font-family: 'OpenSans', sans-serif;
              font-size: 14px;
              color: #212B35;
              text-align: center;
            }
          }
          .modal-footer {
            padding: 4px 28px 30px;
            .btn {
              width: 50%;
              font-family: 'OpenSans', sans-serif;
              font-size: 15px;
              @include crossBrowser(border-radius, 2px);
              padding: 14px 0 13px;
              &:first-child {
                color: #FFF;
                background: #F46363;
                border-color: #F46363;
                margin-right: 13px;
                &:hover {
                  background: #F46363 - 10;
                }

              }
              &:last-child {
                color: #757575;
                background: #fff;
                border-color: #B1B1B1;
                margin-left: 13px;
                &:hover {
                  background: #fff - 5;
                }
              }
            }
          }
        }
        .form-group {
          margin: 8px 0 0;
          label {
            font-family: 'OpenSans', sans-serif;
            font-size: 12px;
            line-height: 17px;
            color: #212B35;
            margin-bottom: 9px;
            sup {
              font-size: 12px;
              color: $error-color;
            }
          }
          textarea {
            font-family: 'OpenSans', sans-serif;
            font-size: 15px;
            color: #040E28;
            height: 120px;
            padding: 12px;
            border: 1px solid #DCE1EA;
            @include crossBrowser(border-radius, 3px);
            &:focus {
              border: 1px solid $primary-color;
            }
          }
          &.has-error {
            textarea {
              border: 1px solid $error-color;
            }

          }
        }
      }

    }
    & ~ .tooltip {
      width: 78px;
      margin-bottom: 4px !important;
      .tooltip-inner {
        font-family: 'OpenSans', sans-serif;
        font-size: 10px;
        line-height: 14px;
        color: #FFFFFF;
        background: #374075;
        &:before {
          border-top-color: #374075;
        }
      }
      &.show {
        opacity: 1;
      }
    }
  }
}