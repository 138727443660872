@import '../../../main/scss/parts/variables';
@import '../../../main/scss/parts/mixins';

/**** placholder ***/
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #A6B1C2 !important;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #A6B1C2 !important;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #A6B1C2 !important;
}

:-moz-placeholder { /* Firefox 18- */
  color: #A6B1C2 !important;
}

/**** loader ***/
.loader-overlay {
  background: $bg-color;
}

/*** fab icon ***/
.fab-icon {
  position: fixed;
  bottom: 64px;
  right: 32px;
  height: 46px;
  width: 46px;
  @include crossBrowser(border-radius, 50%);
  background: $primary-color;
  text-align: center;
  padding-top: 10px;

  i {
    font-size: 26px;
    color: #FFF;
  }
  &:hover {
    opacity: .7;
  }
}

.radio-custom label::before {
  width: 16px;
  height: 16px;
  margin-left: -18px;
  top: 2px;
}
.radio-primary input[type=radio]:checked + input[type=hidden] + label::before,
.radio-primary input[type=radio]:checked + label::before {
  border-width: 6px;
  border-color: $primary-color;
}
