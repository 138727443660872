@import '../../../main/scss/parts/variables';
@import '../../../main/scss/parts/mixins';
@import '../../../main/scss/parts/helpers';

.site-navbar {
  background: #fff;
  height: 60px;
  @include crossBrowser(box-shadow, inset 0 -1px 0 0 #DDDDDD !important);
  .navbar-container {
    height: 60px;
  }
  .navbar-brand {

    img {

    }
  }
  .navbar-right {
    .nav-link {
      padding: 15px;
      padding-right: 24px;
    }
    .navbar-avatar {

      .avatar {
        vertical-align: middle;
        width: 38px;
        height: 38px;
        img {
          width: 38px;
          height: 38px;
        }
      }
      span {
        &.name {
          display: inline-block;
          font-family: 'OpenSans', sans-serif;
          font-size: 14px;
          color: #757575;
          letter-spacing: 0.1px;
          margin-left: 14px;
          vertical-align: middle;
          span {
            display: inline-block;
            opacity: 0.75;
            margin-right: 3px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 120px;
            white-space: nowrap;
            vertical-align: middle;
          }
          i {
            font-size: 16px;
            top: 2px;
          }
        }
      }
    }
    .dropdown-menu {
      min-width: 230px;
    }
  }

  .navbar-header {
    .navbar-toggler {
      color: #76838f;
    }
    .hamburger {
      .hamburger-bar {
        background-color: #76838f;
      }
      &:after, &:before {
        background-color: #76838f !important;
      }
    }
  }
}

.site-menubar {
  background: #fff;
  @include crossBrowser(box-shadow, inset -1px 0 0 0 #DDDDDD);
  .site-menu {
    padding-right: 1px;
    .site-menu-category {
      font-family: 'OpenSans-Bold', sans-serif;
      font-size: 12px;
      color: #A6A6A6;
      letter-spacing: 0.08px;
      line-height: 17px;
      margin-top: 32px;
      margin-bottom: 8px;
      &:first-child {
        margin-top: 38px;
      }
    }
    .site-menu-item {
      font-family: 'OpenSans', sans-serif;
      font-size: 14px;
      color: #212B35;
      letter-spacing: 0.1px;

      & > a {
        color: #212B35;
        line-height: 32px;
        .site-menu-title {
          margin-left: 10px !important;
        }
      }

      &:hover, &.hover, &.active {
        background-color: #F4F5FC;
        & > a {
          color: $primary-color;
          background-color: #F4F5FC;
        }
      }
      &.active {
        border: 0;
        border-left: 4px solid $primary-color;
        & > a {
          padding-left: 26px;
        }
      }
    }
  }
}

.site-menubar-fold {
  .site-menubar {
    width: 130px;
  }
  .page, .site-footer {
    margin-left: 130px;
  }
  .site-menu-category {
    background: #fff;
    margin-bottom: 25px !important;
    height: 25px;
    padding-left: 15px;
    &:first-child {
      visibility: visible;
    }
  }
  .site-menu {
    & > .site-menu-item {
      & > a {
        height: 40px;
        .site-menu-title {
          text-align: left;
          padding-left: 5px;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}


