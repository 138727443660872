@import '../../../main/scss/parts/variables';
@import '../../../main/scss/parts/mixins';
@import '../../../main/scss/parts/helpers';

.page {
  &.history {
    padding-bottom: 30px;
    .page-header {
      padding: 24px 24px 0;
    }
    .page-content {
      .day-group {
        margin-top: 30px;
        .day {
          font-family: 'OpenSans', sans-serif;
          font-size: 14px;
          font-weight: 700;
          color: $grey-color;
          margin-bottom: 10px;
        }
        .day-block {
          width: 100%;
          padding: 30px 30px 0;
          background: #fff;
          &>div {
            border-bottom: 1px solid $grey-color;
          }
          .data {
            margin-bottom: 10px;
            .content {
              font-family: 'OpenSans', sans-serif;
              font-size: 14px;
              span {
                color: #000;
                font-weight: 500;
                &.user {
                  color: $primary-color;
                }
              }
            }
            .details {
              a {
                color: $primary-color;
                &:hover {
                  color: $primary-color;
                  opacity: .8;
                  text-decoration: none;
                }
              }
            }
          }
          .time {
            font-family: 'OpenSans', sans-serif;
            font-size: 12px;
            padding-bottom: 10px;
          }
          &:last-child {
            padding-bottom: 20px;
          }
        }
      }

    }
  }
}