@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans.woff') format('woff'),
  url('../fonts/OpenSans.ttf') format('truetype'),
  url('../fonts/OpenSans.svg#OpenSans') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('../fonts/OpenSans-Light.eot');
  src: url('../fonts/OpenSans-Light.woff2') format('woff2'),
  url('../fonts/OpenSans-Light.woff') format('woff'),
  url('../fonts/OpenSans-Light.ttf') format('truetype'),
  url('../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg'),
  url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../fonts/OpenSans-Bold.eot');
  src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
  url('../fonts/OpenSans-Bold.woff') format('woff'),
  url('../fonts/OpenSans-Bold.ttf') format('truetype'),
  url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg'),
  url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

