@import 'main/scss/custom.layout';
@import 'pages/login/scss/login';
@import 'pages/store/scss/store';
@import 'pages/applications/scss/applications';
@import 'pages/versions/scss/versions';
@import 'pages/add-version/scss/add-version';
@import 'pages/add-product/scss/add-product';
@import 'pages/products/scss/products';
@import 'pages/groups/scss/groups';
@import 'pages/add-group/scss/add-group';
@import 'pages/applications-list/scss/applications-list';
@import 'pages/add-application/scss/add-application';
@import 'pages/clients/scss/clients';
@import 'pages/add-client/scss/add-client';
@import 'pages/users/scss/users';
@import 'pages/add-user/scss/add-user';
@import 'pages/testers/scss/list';
@import 'pages/add-tester/scss/add-tester';
@import 'pages/history/scss/history';
@import 'pages/account/scss/account';
@import 'main/scss/parts/responsive';