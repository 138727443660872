@import '../../../main/scss/parts/mixins';

/* Helpers
-----------------------------------*/
.mg-0 {
  margin: 0;
}
.pd-0 {
  padding: 0;
}

.h-85 {
  height: 85px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-20 {
  margin-top: 20px;
}


@keyframes refresh {
  from {
    @include crossBrowser(transform, rotate(0deg));
  }
  to {
    @include crossBrowser(transform, rotate(360deg));
  }
}
