@import '../../../main/scss/parts/variables';
@import '../../../main/scss/parts/mixins';
@import '../../../main/scss/parts/helpers';

.add-version {
  padding-top: 0;
  .page {
    background: #fff;
    padding-bottom: 288px;
    .page-header {
      background: #fff;
      padding: 0;
     @include crossBrowser(box-shadow, inset 0 -1px 0 0 #DDDDDD);
      div {
        padding: 0;
        margin: 0;
        &.close {
          width: 68px;
          height: 60px;
          float: left;
          padding: 8px 13px;
          text-align: center;
          opacity: 1;
          @include crossBrowser(border-right, 1px solid #DDDDDD);
          i {
            font-size: 24px;
            color: #757575;

            padding-top: 11px
          }
          &:hover {
            opacity: .7;
          }
        }
        &.title {
            text-align: center;
          h2 {
            font-family: 'OpenSans-Bold',sans-serif;
            font-size: 18px;
            line-height: 24px;
            color: #212B35;
            margin: 10px 0 2px;
          }
          h4 {
            font-family: 'OpenSans',sans-serif;
            font-size: 12px;
            color: #212B35;
            line-height: 17px;
            margin: 0 0 8px;
            span {
              color: #a6a6a6;
            }
          }
        }
        &.add {
          .btn {
            width: 202px;
            height: 60px;
            font-family: 'OpenSans',sans-serif;
            font-size: 15px;
            color: #FFFFFF;
            background: $primary-color;
            @include crossBrowser(border-radius, 0);
            border: 0;
            &.disabled {
              color: #A6A6A6;
              background: #EFEFEF;
              &:hover {
                background: #EFEFEF;
              }
            }
            &:hover {
              background: $primary-color + 20;
            }
          }
        }
      }
    }
    .page-content {
      background: #fff;
      .form {
        width: 320px;
        padding: 0 10px;
        margin: 30px auto 0;
        .form-group, .form-group-file {
          margin-bottom: 5px;
          label {
            font-family: 'OpenSans',sans-serif;
            font-size: 12px;
            line-height: 17px;
            color: #646D82;
            margin-bottom: 9px;
            sup {
              font-size: 12px;
              color: $error-color;
            }
          }
          input, textarea {
            font-family: 'OpenSans',sans-serif;
            font-size: 15px;
            color: #040E28;
            height: 44px;
            min-height: 44px;
            padding: 7px 12px;
            border: 1px solid #DCE1EA;
            @include crossBrowser(border-radius, 3px);
            &:focus {
              border: 1px solid $primary-color;
            }
          }
          textarea {
            height: 78px;
            min-height: 78px;
            padding: 12px;
          }
          .error-msg  {
            font-family: 'OpenSans', sans-serif;
            font-size: 11px;
            color: $error-color;
            text-align: left;
            line-height: 18px;
            height: 18px;
            margin-top: 5px;
            opacity: 0;
            @include crossBrowser(transition, opacity .2s ease-in-out);
            span {
              display: none;
              &.server-msg {
                display: block;
              }
            }
          }
          &.has-error {
            input, textarea{
              border: 1px solid $error-color;
            }
            .error-msg  {
              opacity: 1;
            }
          }
          &.ipa {
            margin-bottom: 23px;
            input {
              height: 100%;
              padding: 0;
            }
            .dropify-wrapper {
              height: 70px;
              border: 1px dashed #5C6AC4;
              .file-icon {
                font-size: 22px;
                color: #ACB6C6;
                line-height: 21px;
                vertical-align: middle;
              }
              .dropify-message {
                p {
                  font-family: 'OpenSans',sans-serif;
                  font-size: 12px;
                  color: #757575;
                  line-height: 21px;
                  margin-top: 0;
                  vertical-align: middle;
                  span {
                    color: $primary-color;
                  }
                  &:not(.dropify-error) {
                    display: inline-block;
                  }
                  &.dropify-error {
                    color: $error-color;
                    font-weight: 400;
                  }

                }
              }
              .dropify-errors-container {
                margin-top: 17px;
                ul {
                  padding-left: 0;
                  li {
                    font-family: 'OpenSans',sans-serif;
                    font-size: 12px;
                    line-height: 21px;
                    list-style: none;
                    color: $error-color;
                  }
                }
              }
              &.has-error {
                border: 1px dashed $error-color;
                .dropify-message {
                  display: none;
                }
              }
            }
            .dropify-preview {
              .dropify-render {
                i {
                  font-size: 40px;
                }
                .dropify-extension {
                  font-family: 'OpenSans',sans-serif;
                  font-size: 12px;
                  font-weight: normal;
                  line-height: 21px;
                  margin-top: 5px;
                }
              }
              .dropify-infos{
                .dropify-infos-inner {
                  p {
                    font-family: 'OpenSans',sans-serif;
                    font-weight: normal;
                  }
                  p.dropify-infos-message {
                    margin-top: 5px;
                    padding-top: 5px;
                  }
                }
              }
            }
            .has-preview {
              border: 1px dashed $primary-color !important;
            }
            &.has-error-file {
              .dropify-wrapper {
                border: 1px dashed $error-color;
              }
            }
          }
          &.icon {
            input {
              padding: 0;
            }
            .dropify-wrapper {
              width: 98px;
              height: 25px;
              border: 0;
              background: #EFEFEF;
              @include crossBrowser(border-radius, 2px);
              .file-icon {
               display: none;
              }
              .dropify-message {
                p {
                  font-family: OpenSans;
                  font-size: 10px;
                  color: $primary-color;
                  line-height: 21px;
                  margin-top: 0;
                  vertical-align: middle;
                  span {
                    color: $primary-color;
                  }
                  &:not(.dropify-error) {
                    display: inline-block;
                  }
                  &.dropify-error {
                    color: $error-color;
                    font-weight: 400;
                  }

                }
              }
              .dropify-preview {
                .dropify-infos{
                  .dropify-infos-inner {
                    margin: 0;
                    padding: 0 5px;
                    .dropify-filename {
                      display: none;
                    }
                    .dropify-infos-message {
                      font-family: 'OpenSans',sans-serif;
                      font-weight: normal;
                      margin: 0;
                      padding: 0;
                      overflow: visible;
                      white-space: normal;
                      line-height: 14px;
                      opacity: .7;
                      &:before {
                        display: none;
                      }
                    }
                  }
                }
              }
              &.has-error {
                .dropify-message {
                  display: none;
                }
              }
              &.has-preview {
                width: 52px;
                height: 52px;
                border: none;
                padding: 0;
                .dropify-preview {
                  padding: 0;
                }
              }
            }
            .dropify-errors-container {
              padding-top: 2px;
              ul {
                padding-left: 0;
                li {
                  font-family: 'OpenSans',sans-serif;
                  font-size: 10px;
                  line-height: 10px;
                  list-style: none;
                  color: $error-color;
                }
              }
            }
          }
          &.has-error-file {
            .dropify-wrapper {
              border: 1px dashed $error-color;
            }
          }
          &.disabled {
            opacity: 0.5;
          }
        }

        div {
          .check {
            position: relative;
            &>div {
              position: absolute;
              right: 0;
              top: 28px;
              .text {
                display: inline-block;
                font-family: 'OpenSans',sans-serif;
                font-size: 12px;
                color: #939EAB;
                line-height: 20px;
                &:first-child {
                  padding-right: 22px;
                }
                &:last-child {
                  padding-left: 8px;
                }
              }
              .checkbox-custom {
                padding-left: 0;
                float: none!important;
                label {
                  &:before, &:after {
                    width: 16px;
                    height: 16px;
                    margin-top: 3px;
                    margin-left: -15px;
                  }
                  &:after {
                    font-size: 10px;
                    line-height: 14px;
                    font-weight: 700;
                  }
                  a {
                    margin-left: 0;
                    color: $primary-color;
                  }
                }
                input[type=checkbox] {
                  &:checked {
                    &+label {
                      &:before {
                        border-width: 8px;
                        background-color: #fff;
                        border: 1px solid $primary-color;
                      }
                      &:after {
                        color: $primary-color;
                      }
                    }
                  }
                }
                &.error {
                  label {
                    &:before {
                      border: 1px solid $error-color;
                    }
                  }
                }
              }
            }

          }

        }
        .btn-add {
          width: 100%;
          font-family: 'OpenSans',sans-serif;
          font-size: 15px;
          color: #FFFFFF;
          background: $primary-color;
          @include crossBrowser(border-radius, 0);
          border: 0;
          margin-top: 32px;
          padding: 14px 5px 13px;
          &.disabled {
            color: #A6A6A6;
            background: #EFEFEF;
            &:hover {
              background: #EFEFEF;
            }
          }
          &:hover {
            background: $primary-color + 20;
          }
        }
      }
    }
  }
}
.error-msg-file {
  ul {
    padding-left:0;
    li {
      font-family: 'OpenSans', sans-serif;
      list-style-type: none;
      font-size: 11px;
      color: #F46363;
      text-align: left;
      line-height: 18px;
      height: 18px;
      margin-top: 5px;
      opacity: 0.9;
      webkit-transition: opacity 0.2s ease-in-out;
      -moz-transition: opacity 0.2s ease-in-out;
      -ms-transition: opacity 0.2s ease-in-out;
      transition: opacity 0.2s ease-in-out;
    }
  }

}

