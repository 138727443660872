html, body {
  min-width: auto !important;
}

.page {
  padding-bottom: 30px;
  .page-header {
    padding: 24px 24px 16px;
    .breadcrumb {
      margin-bottom: 16px;
      .breadcrumb-item {
        font-family: 'OpenSans', sans-serif;
        font-size: 12px;
        a {
          color: #5C6AC4;
        }
        &.active {
          a {
            color: #000;
          }
        }
      }

    }
    .page-title {
      font-family: 'OpenSans-Bold', sans-serif;
      font-size: 20px;
      line-height: 27px;
      color: #212B35;
    }
    .page-subtitle {
      font-family: 'OpenSans', sans-serif;
      font-size: 12px;
      font-weight: normal;
      line-height: 17px;
      color: #A6A6A6;
      margin: 0;
    }
  }
  .page-content {
    padding: 0 24px;
  }
}
